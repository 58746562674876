<template>
  <div class="modal fade" :id="id" tabindex="-1">
    <div
      class="modal-dialog modal-fullscreen modal-fullscreen-lg-down modal-dialog-centered"
    >
      <div class="modal-content">
        <div class="modal-body text-center">
          <img
            :src="
              require('@/assets/img/products/' +
                products +
                '-screenshot-' +
                img +
                '.png')
            "
            alt=""
            class="img-fluid"
          />

          <button class="btn pt-4 pb-4" type="button" data-bs-dismiss="modal">
            <span class="ps-4 pe-4">Ablak bezárása</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    products: String,
    img: String,
  },
};
</script>
